import { Link } from '~components'
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Images } from '~data'
import Footer from "./style"
export default function FooterOne() {
    return (
        <footer className='footer_helios'>
            <div className='container'>
                <div className="row pt-5">
                    <div className='col-xs-12 col-md-3'>
                        <ul>
                            <li><h5>Acerca de Nosotros</h5></li>
                            <li><Link to='/acerca-de-nosotros'>Misión</Link></li>
                            <li><Link to='/acerca-de-nosotros'>Visión</Link></li>
                            <li><Link to='/acerca-de-nosotros'>Valores</Link></li>
                            <li><Link to='/acerca-de-nosotros'>Países</Link></li>
                            <li><Link to='/acerca-de-nosotros'>Clientes</Link></li>
                            <li><Link to='/acerca-de-nosotros'>Socios</Link></li>
                        </ul>
                    </div>
                    <div className='col-xs-12 col-md-3'>
                        <ul>
                            <li><h5>Soluciones</h5></li>
                            <li><Link to='/drones-atv-rov-uav'>Drones / ATV / ROV UAV</Link></li>
                            <li><Link to='/ciudades-inteligentes'>Ciudad Inteligentes</Link></li>
                            <li><Link to='/ciberseguridad'>Ciberseguridad</Link></li>
                            <li><Link to='/desarrollo-de-software'>Desarrollo de software</Link></li>
                            <li><Link to='/broker-de-seguros'>Broker de seguros</Link></li>
                            <li><Link to='/transformacion-digital'>Transformación digital</Link></li>
                        </ul>
                    </div>

                    <div className='col-xs-12 col-md-3'>
                        <ul>
                            <li><h5>Gobierno corporativo</h5></li>
                            <li><Link to='/politica-anticorrupcion'>Política anticorrupción</Link></li>
                            <li><Link to='/codigo-de-etica'>Código de ética</Link></li>
                            <li><Link to='/politica-de-cookies'>Política de cookies</Link></li>
                            <li><Link to='/politica-ambiental'>Política ambiental</Link></li>
                            <li><Link to='/politica-seguridad'>Política de seguridad</Link></li>
                            <li><Link to='/politica-de-derechos-humanos'>Política de derechos humanos</Link></li>
                        </ul>
                    </div>

                    <div className='col-xs-12 col-md-3'>
                        <ul>
                            <li><h5>Contacto</h5></li>
                            <li><Link to='/contact'>Envia un mensaje</Link></li>
                            <ul className='row_social_media'>
                                <li><a href='https://www.facebook.com/pages/category/Internet-company/Helios-Digital-102554322334871/' target="_blank"><i className="fab fa-facebook-square"></i></a></li>
                                <li><a href='https://twitter.com/helios_digital_' target="_blank"><i className="fab fa-twitter"></i></a></li>
                                <li><a href='https://instagram.com/heliosdigital_?utm_medium=copy_link' target="_blank"><i className="fab fa-instagram"></i></a></li>
                                {/* <li><a href='#'><i className="fab fa-linkedin" target="_blank"></i></a></li> */}
                            </ul>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className='col-xs-12 col-md-3'>
                        <ul>
                            <li><h5>Profesiones</h5></li>
                            <li><Link to='/empleos'>Empleos</Link></li>
                        </ul>
                    </div>
                    {/* <div className='col-xs-12 col-md-3'>
                        <ul>
                            <li><h5>Noticias y eventos</h5></li>
                            <li><a href='#'>Blog</a></li>
                        </ul>
                    </div> */}

                    <div className='col-xs-12 col-md-3'>
                        <ul>
                            <li><h5>Relaciones con inversionistas</h5></li>
                            <li><Link to='/contacto-para-investors'>Contacto para Investors</Link></li>
                            {/* <Link to="/files/manual.pdf" target="_blank" download>Download</Link> */}
                            <li><Link to='../files/Helios_Digital_Group_Brochure.pdf' target="_blank" download>Brochure de Investors</Link></li>
                        </ul>
                    </div>

                    {/* <div className='col-xs-12 col-md-3'>
                        <ul className="traductor">
                            <li><i className="fas fa-globe"></i> <h5>País/Idioma</h5></li>
                                
                        </ul>
                    </div> */}
                </div>
                <hr />
                <div className='row'>
                    <div className='col-12'>
                    <p>© 2022 Helios Digital Group. All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}